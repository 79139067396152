import { ExpirationSubscription } from "@/models/entities/expiration-subscription.interface";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { ApiService } from "vuelando";

const controller = "subscriptionplans";

class SubscriptionPlansService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetAllOffert() {
    return this.getAll<PromotionPlans[]>(`${controller}/getAll`);
  }

  getById(roleId: number) {
    return this.getAll<PromotionPlans>(
      `${controller}/getByID?roleId=${roleId}`
    );
  }

  getExpiration() {
    return this.getAll<string>(`${controller}/getexpiration`);
  }
}

export const subscriptionPlansService = new SubscriptionPlansService();
