import { Country } from "@/models/entities/country.interface";
import { ApiService } from "vuelando";

const controller = "country";

class CountryService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetAllCountry() {
    return this.getAll<Country[]>(`${controller}/getAll`);
  }
}

export const countryService = new CountryService();
